// Register navbar namespace
API.GMaps = {};

//Initialized new map, based on lement's id + config (lat, lng, zoom)
API.GMaps.Init = function(containerId, lat, lng, zoom, enableControls = true) {

  if ($('#' + containerId).length != 0) {
    var map = new google.maps.Map($("#" + containerId)[0], {
      zoom: zoom,
      center: {
        lat: lat,
        lng: lng
      },
      disableDefaultUI: !enableControls,
      mapTypeId: "OSM",
      mapTypeControl: false,
      streetViewControl: false
    });


    // Open Street map layer implementation

    map.mapTypes.set("OSM", new google.maps.ImageMapType({
      getTileUrl: function(coord, zoom) {
        // "Wrap" x (logitude) at 180th meridian properly
        // NB: Don't touch coord.x because coord param is by reference, and changing its x property breakes something in Google's lib
        var tilesPerGlobe = 1 << zoom;
        var x = coord.x % tilesPerGlobe;
        if (x < 0) {
          x = tilesPerGlobe + x;
        }
        // Wrap y (latitude) in a like manner if you want to enable vertical infinite scroll

        return "https://tile.openstreetmap.org/" + zoom + "/" + x + "/" + coord.y + ".png";
      },
      tileSize: new google.maps.Size(256, 256),
      name: "OpenStreetMap",
      maxZoom: 18
    }));

    API.GMaps.InfoWindow = new google.maps.InfoWindow();
    API.GMaps.LatLngBounds = new google.maps.LatLngBounds();

    google.maps.event.addDomListener(window, "resize", function() {
        var center = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
    });

    return map;
  } else {
    return null;
  }
};

API.GMaps.AddMarker = function(map, lat, lng, options = {}, card = null) {
  var latLng = new google.maps.LatLng(lat, lng);
  var marker = new google.maps.Marker({
    position: latLng,
    map: map,
    title: options.title ? options.title : ""
  });

  if (options.appendToBounds) {
    API.GMaps.LatLngBounds.extend(latLng);
  }

  if (card) {
    google.maps.event.addListener(marker, 'click', function() {
      API.GMaps.InfoWindow.close();
      API.GMaps.InfoWindow.setContent("<div class='ginfo'>" +
        "<h1 class='gheader'>" + (card.header ? card.header : "") + "</h1>" +
        "<div class='gbody'>" + (card.body ? card.body : "") + "</div>" +
        "<div class='gfooter'>" + (card.footer ? card.footer : "") + "</div>" +
        "</div>");
      API.GMaps.InfoWindow.open(map, marker);
    });
  }
}

API.GMaps.FitBounds = function(map) {
  map.fitBounds(API.GMaps.LatLngBounds);
}
